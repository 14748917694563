import logo from "./images/logo.svg";
import Hero from "./components/Hero";
import Install from "./components/Install";
import DeeplinkButton from "../../components/DeeplinkButton";

const Page = ({ route }) => {
  return (
    <div id="app">
      <div class="header">
        <a href="https://betwarrior.bet">
          <img src={logo} class="logo" />
        </a>
      </div>
      <Hero />
      <div class="content">
        <h1>{route.title}</h1>
        <p>{route.description}</p>
        <div class="action">
          <DeeplinkButton>Open BetWarrior App</DeeplinkButton>
        </div>
        <Install />
      </div>
    </div>
  );
};

export default Page;
