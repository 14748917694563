import { h, createContext } from "preact";
import Router from "preact-router";

// Config
import config from "client/config.js";
import urls from "client/urls.js";
import Page from "client/Page.jsx";
import "client/style.css";

export const Config = createContext("config");

const App = () => {
  const { routes } = config;
  const namespace = config.namespace ? `/${config.namespace}` : "";

  return (
    <Config.Provider value={{ config, urls }}>
      <div id="app">
        <Router>
          {Object.keys(routes).map((key) => {
            const path = `${namespace}/${key}`;
            const route = routes[key];
            return <Page path={path} route={route} />;
          })}
        </Router>
      </div>
    </Config.Provider>
  );
};

export default App;
